import React, { FC, useEffect, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Button, notification } from 'antd';
import { nanoid } from 'nanoid';

import { MainLayout } from 'components/Layout/MainLayout';

import { PageTitles } from '../../constants/pageTitles';
import { DragAndDropTable } from './components/DragAndDropTable/DragAndDropTable';
import './styles/index.css';
import { IRow } from './types/types';
import styles from './styles/MainB2C.module.less';
import {
  ENTITY_TYPE,
  MAIN_B2C_ITEM_SETTINGS,
  MAIN_B2C_NUMBER_FACT_ITEM_TYPE,
  MAIN_B2C_PREVIEW_BLOCK_TYPE,
  MAIN_B2C_PREVIEW_ITEM_TYPE,
  MAIN_B2C_THREE_HEADERS_ITEM_TYPE,
} from './const';
import { apiClient } from '../../utils/http';
import { getUrlWithParams, urls } from '../../store/api';
import { useAppSelector } from '../../store';
import { pick } from '../../utils/helpers';
import { useBlocker } from '../../hooks/useBlocker';

const handleEntityType = type => {
  switch (type) {
    case MAIN_B2C_PREVIEW_ITEM_TYPE.WIDE:
    case MAIN_B2C_PREVIEW_ITEM_TYPE.TWO_THIRDS:
    case MAIN_B2C_PREVIEW_ITEM_TYPE.HALF:
    case MAIN_B2C_PREVIEW_ITEM_TYPE.ONE_THIRDS:
      return { type: ENTITY_TYPE.PREVIEW, data: ENTITY_TYPE.PREVIEW };
    case MAIN_B2C_NUMBER_FACT_ITEM_TYPE.NUMBER_FACT:
      return {
        type: ENTITY_TYPE.DIGIT_FACTS,
        data: `${ENTITY_TYPE.DIGIT_FACTS}Block`,
      };
    case MAIN_B2C_THREE_HEADERS_ITEM_TYPE.THREE_HEADERS:
      return {
        type: ENTITY_TYPE.THREE_TITLES,
        data: `${ENTITY_TYPE.THREE_TITLES}Block`,
      };
    case MAIN_B2C_PREVIEW_BLOCK_TYPE.ARTICLES:
    case MAIN_B2C_PREVIEW_BLOCK_TYPE.ROUTES:
    case MAIN_B2C_PREVIEW_BLOCK_TYPE.RESORTS:
    case MAIN_B2C_PREVIEW_BLOCK_TYPE.EVENTS:
      return {
        type: ENTITY_TYPE.PREVIEW_BLOCK,
        data: ENTITY_TYPE.PREVIEW_BLOCK,
      };
    default:
      return null;
  }
};

export const MainB2C: FC = () => {
  const [data, setData] = useState<IRow[]>([]);
  const [isSomethingChanged, setIsSomethingChanged] = useState<boolean>(false);

  const { b2cUrl } = useAppSelector(state => state.configSlice);

  const createSection = () => {
    const newRow: IRow = { key: String(nanoid()), items: [] };
    setData([newRow, ...data]);
  };

  const handleAllData = (arr): IRow[] => {
    const alp = {
      digitFacts: MAIN_B2C_NUMBER_FACT_ITEM_TYPE.NUMBER_FACT,
      threeTitles: MAIN_B2C_THREE_HEADERS_ITEM_TYPE.THREE_HEADERS,
    };

    const updatedData = arr.content.reduce((acc, curr) => {
      let item = curr[curr.entityType];

      if (!item) item = curr[`${curr.entityType}Block`];

      acc[curr.rowNumber] ??= {
        key: curr.rowNumber,
        items: [],
      };

      acc[curr.rowNumber].items.push({
        ...item,
        ...(MAIN_B2C_ITEM_SETTINGS?.[
          curr.entitySubtype ?? alp[curr.entityType]
        ] || {}),
        ...curr,
        key: curr.id,
      });

      return acc;
    }, {});

    return Object.values(updatedData);
  };

  const makeReqData = () => {
    return data.reduce((acc, curr, rowIndex) => {
      curr.items.forEach((smallItem, index) => {
        const copy = JSON.parse(JSON.stringify(smallItem));

        const entityType = handleEntityType(smallItem.type);

        let content;
        switch (entityType.type) {
          case ENTITY_TYPE.PREVIEW:
            content = pick(
              copy,
              'id',
              'lang',
              'link',
              'tags',
              'type',
              'image',
              'style',
              'title',
              'status',
              'createdAt',
              'rowNumber',
              'sortPriority',
              'shortDescription',
              'hideShortDescription'
            );

            break;
          case ENTITY_TYPE.THREE_TITLES:
            content = pick(
              copy,
              'id',
              'createdAt',
              'updatedAt',
              'author',
              'creator',
              'editor',
              'publishedAt',
              'title',
              'lang',
              'status',
              'previews'
            );
            break;
          case ENTITY_TYPE.DIGIT_FACTS:
            content = pick(
              copy,
              'id',
              'createdAt',
              'updatedAt',
              'author',
              'creator',
              'editor',
              'publishedAt',
              'title',
              'digit',
              'unit',
              'description',
              'link',
              'lang',
              'status'
            );
            break;
          case ENTITY_TYPE.PREVIEW_BLOCK:
            content = pick(
              copy,
              'title',
              'type',
              'sortPriority',
              'lang',
              'status'
            );
        }

        const item = {
          mainPageId: 1,
          entitySubtype:
            MAIN_B2C_PREVIEW_ITEM_TYPE[copy.type.toUpperCase()] ||
            MAIN_B2C_PREVIEW_BLOCK_TYPE[copy.type.toUpperCase()] ||
            null,
          entityType: entityType.type,
          lang: 'ru',
          id: nanoid(),
          rowNumber: rowIndex,
          entityId: copy.entityId || null,
          sortPriority: index,
          status: 'published',
          [entityType.data]: { ...content },
        };
        acc.push(item);
      });

      return acc;
    }, []);
  };

  const onPreview = async () => {
    const requestData = makeReqData();

    try {
      const { data: response } = await apiClient.post(urls.api.preview.post, {
        source: 'mainPageB2C',
        sourceId: 1,
        data: requestData,
      });

      window.open(
        `${b2cUrl}?${response.source}Preview=${response.id}`,
        '_blank'
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onSave = async () => {
    const requestData = makeReqData();

    try {
      const { data: response } = await apiClient.put(
        getUrlWithParams(urls.api.mainPageB2C.put, { id: 1 }),
        { content: requestData, lang: 'ru', status: 'published' }
      );
      setData(handleAllData(response));
      notification.success({
        message: 'Главная страница B2C успешно опубликована.',
      });
      setIsSomethingChanged(false);
    } catch (e) {
      console.log(e);
      notification.error({
        message: 'При публикации главной B2C произошла ошибка.',
      });
    }
  };

  const getInitialData = async () => {
    try {
      const { data: getAllData } = await apiClient.get(
        getUrlWithParams(urls.api.mainPageB2C.get, {
          id: 1,
        })
      );

      setData(handleAllData(getAllData));
    } catch (e) {
      console.log(e);
    }
  };

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = ' ';
  };

  useBlocker(() => {
    if (isSomethingChanged) {
      return !window.confirm(
        `Вы уверены, что хотите перейти? У вас есть несохранённые изменения.`
      );
    }
    return false;
  }, isSomethingChanged);

  useEffect(() => {
    (async () => {
      await getInitialData();
    })();

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <MainLayout
      pageTitle={PageTitles.mainB2C}
      extraButtonsList={[
        <div key='1'>
          <Button onClick={createSection}>Добавить секцию</Button>
        </div>,
      ]}
      // aside={}
    >
      <Content>
        <div className={styles.configurator}>
          <DragAndDropTable
            data={data}
            setData={setData}
            setIsSomethingChanged={setIsSomethingChanged}
          />

          {data.length === 0 && <p>Страница пуста.</p>}

          <div className={styles.buttonsContainer}>
            {isSomethingChanged && (
              <Button
                type='primary'
                htmlType='button'
                onClick={async () => {
                  await getInitialData();
                  setIsSomethingChanged(false);
                }}
                // loading={isLoading}
              >
                Восстановить
              </Button>
            )}

            {data.length > 0 && (
              <Button
                type='primary'
                htmlType='button'
                onClick={() => {
                  setData([]);
                  setIsSomethingChanged(true);
                }}
                // loading={isLoading}
              >
                Очистить
              </Button>
            )}

            <Button
              type='primary'
              htmlType='button'
              onClick={onPreview}
              // loading={isLoading}
            >
              Превью
            </Button>
            <Button
              type='primary'
              htmlType='button'
              onClick={onSave}
              disabled={!isSomethingChanged}
              // loading={isLoading}
            >
              Сохранить
            </Button>
          </div>
        </div>
      </Content>
    </MainLayout>
  );
};
