import React from 'react';
import { Card } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { SeparatorImage } from './SeparatorImage';

type TProps = {
  onChange: (value: any) => void;
  separator: any;
  name: string;
};

export class Separator extends React.Component<TProps> {
  static displayName = 'FormContent:Separator';

  static defaultProps = {
    separator: '<hr></hr>',

    onChange: () => undefined,
  };

  render(): React.ReactNode {
    return (
      <Content style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Card>
          <SeparatorImage />
          <input
            name={this.props.name}
            type='hidden'
            defaultValue={this.props.separator}
          />
        </Card>
      </Content>
    );
  }
}
