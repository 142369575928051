import React from 'react';
import {
  PicCenterOutlined,
  PicLeftOutlined,
  PicRightOutlined,
} from '@ant-design/icons';
import { Radio } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

interface FloatButtonsProps {
  defaultValue?: string;
  disabledButton?: boolean;
  name: string;
}

export const FloatButtons: React.FC<FloatButtonsProps> = ({
  defaultValue = '',
  disabledButton = false,
  name,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        return (
          <Radio.Group defaultValue={defaultValue} {...field} {...fieldState}>
            <Radio.Button value='left' disabled={disabledButton}>
              <PicLeftOutlined title='Слева от текста' />
            </Radio.Button>
            <Radio.Button value=''>
              <PicCenterOutlined title='По центру' />
            </Radio.Button>
            <Radio.Button value='right' disabled={disabledButton}>
              <PicRightOutlined title='Справа от текста' />
            </Radio.Button>
          </Radio.Group>
        );
      }}
      control={control}
    />
  );
};
