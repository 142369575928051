import React from 'react';

export const SeparatorImage = () => {
  return (
    <svg
      width='100%'
      height='23'
      viewBox='0 0 701 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.25'>
        <path
          d='M337.218 21.6931H367.714L352.466 1.69336L345.451 10.9324'
          stroke='#0161FD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <path
          d='M342.773 7.56299L332 21.6932H353.545L342.773 7.56299Z'
          stroke='#0161FD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <line x1='325' y1='12.1934' y2='12.1934' stroke='#0161FD' />
        <line x1='701' y1='12.1934' x2='376' y2='12.1934' stroke='#0161FD' />
      </g>
    </svg>
  );
};
