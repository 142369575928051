import React from 'react';

import { PublishedEntityAsyncSelect } from 'components/form/selects/PublishedEntityAsyncSelect';

type TProps = {
  widget: any;
  withWidgetsDescription: any;
  name: string;
  errors: any;
  index: number;
};

export class Entity extends React.Component<TProps> {
  getWidgetParamsHash(type) {
    const hash = {
      attractions: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите достопримечательность',
        type: 'attractions',
      },
      resorts: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите курорт',
        type: 'resorts',
      },
      articles: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите статью',
        type: 'articles',
        nameQueryField: 'nameQuery',
      },
      news: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите новость',
        type: 'news',
      },
      events: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите событие',
        type: 'events',
        nameQueryField: 'nameQuery',
      },
      hotels: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите отель',
        type: 'hotels',
      },
      restaurants: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите кафе или ресторан',
        type: 'restaurants',
      },
      routes: {
        Component: PublishedEntityAsyncSelect,
        label: 'Выберите маршрут',
        type: 'routes',
      },
    };

    return hash[type];
  }

  render() {
    const { widget, index } = this.props;
    const {
      Component = null,
      label = '',
      type = '',
    } = this.getWidgetParamsHash(widget.type) || {};

    if (!Component) return null;

    return (
      <React.Fragment>
        <Component
          name={`${this.props.name}.${widget.type}[${index}]`}
          errors={this.props.errors}
          label={label}
          type={type}
        />
      </React.Fragment>
    );
  }
}
