import React, { useEffect } from 'react';
import { Button, Form, Modal, Row, Typography } from 'antd';
import ReactCropper from 'react-cropper';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { omit, pick } from 'utils/helpers';
import { useDeepEffect } from 'utils/useDeepEffect';
import yup from 'utils/yup';
import { getImageUrl } from 'utils/image';
import { TImage } from 'types/image';

import { CheckboxField } from '../base/CheckboxField';
import { InputField } from '../base/InputField';
import { TextAreaField } from '../base/TextAreaField';
import { FloatButtons } from '../FloatButtons/FloatButtons';
import styles from './CropperModal.module.less';

type TRequestData = {
  crop: any;
  cropCanvas: any;
  source: string;
  author: string;
  description?: string;
};

type TProps = {
  value: TImage;
  onChange: (value: any) => void;
  setCroppedImageUrl?: (value: string) => void;
  originalImageUrl: string;
  setCropModalIsOpen: (value: boolean) => void;
  cropModalIsOpen: boolean;
  aspectRatio?: number;
  isNoInputs?: boolean;
  setModalIsOpen?: (value: boolean) => void;
  isShowPositionFields?: boolean;
};

export const validationSchema = yup.object().shape({
  source: yup.string().required(),
  author: yup.string().required(),
  description: yup.string(),
});

const emptySchema = yup.object().shape({});

export const CropperModal: React.FC<TProps> = ({
  value,
  onChange,
  setCroppedImageUrl,
  originalImageUrl,
  setCropModalIsOpen,
  cropModalIsOpen,
  aspectRatio,
  isNoInputs,
  setModalIsOpen,
  isShowPositionFields = false,
}) => {
  const resolver = yupResolver(isNoInputs ? emptySchema : validationSchema);
  const initialData = value?.crop || undefined;

  const methods = useForm<TImage>({
    defaultValues: value,
    resolver,
  });

  const isFullImage = methods.watch('isFull');

  const [cropper, setCropper] = React.useState(null);

  const submit = ({ description, author, source, isFull, float }: TImage) => {
    const data = pick(cropper.getData(true), 'x', 'y', 'width', 'height');
    const cropCanvas = cropper.getCroppedCanvas();
    const position = isShowPositionFields ? { isFull, float } : {};
    const requestData: TRequestData = {
      ...omit(value, 'description'),
      crop: data,
      cropCanvas,
      source,
      author,
      ...position,
    };

    if (description?.length) {
      requestData.description = description;
    }
    onChange(requestData);
    if (typeof setCroppedImageUrl === 'function') {
      if ('path' in value) {
        setCroppedImageUrl(getImageUrl(requestData));
      } else {
        setCroppedImageUrl(cropCanvas.toDataURL());
      }
    }
    setCropModalIsOpen(false);
    if (setModalIsOpen) setModalIsOpen(false);
  };

  const handleCancel = () => {
    setCropModalIsOpen(false);
    if (setModalIsOpen) setModalIsOpen(false);
  };

  useEffect(() => {
    if (isFullImage) {
      methods.setValue('float', '');
    }
  }, [isFullImage]);

  useDeepEffect(() => {
    const position = isShowPositionFields
      ? { isFull: value?.isFull, float: value?.float }
      : {};

    methods.reset({
      author: value?.author,
      description: value?.description,
      source: value?.source,
      ...position,
    });
  }, [value]);

  return (
    <Modal
      title='Редактировать изображение'
      open={cropModalIsOpen}
      onCancel={handleCancel}
      width={840}
      footer={[
        <Button key='cancel' onClick={handleCancel}>
          Отмена
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={() => methods.handleSubmit(submit)()}
        >
          Применить
        </Button>,
      ]}
      destroyOnClose
    >
      <FormProvider {...methods}>
        <form
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className='ant-form ant-form-vertical'
        >
          <Row>
            {!isNoInputs && (
              <div className={styles.inputs}>
                <InputField
                  required
                  label='Автор'
                  name='author'
                  placeholder='Введите имя автора'
                  autoFocus
                  showCount
                  maxLength={255}
                />
                <InputField
                  required
                  label='Ссылка на источник'
                  name='source'
                  placeholder='Укажите ссылку на источник'
                  showCount
                  maxLength={255}
                />
                {isShowPositionFields && (
                  <>
                    <Form.Item
                      className={styles.imageSettimg}
                      label='Положение изображения'
                    >
                      <FloatButtons name='float' disabledButton={isFullImage} />
                      <CheckboxField
                        formItemStyle={{ marginBottom: 0 }}
                        name='isFull'
                      >
                        На всю ширину
                      </CheckboxField>
                    </Form.Item>
                  </>
                )}

                <TextAreaField
                  label='Описание изображения'
                  name='description'
                  placeholder='Описание изображения'
                  showCount
                  maxLength={255}
                  rows={10}
                />
              </div>
            )}

            <div className={styles.image}>
              <Typography.Text>Редактировать фотографию</Typography.Text>

              <div className={styles.image__in}>
                <div className={styles.image__wrapper}>
                  <ReactCropper
                    data={initialData}
                    aspectRatio={aspectRatio || 3 / 2}
                    initialAspectRatio={aspectRatio || 3 / 2}
                    src={originalImageUrl}
                    style={{ height: '100%', width: '100%' }}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive
                    checkOrientation={false}
                    onInitialized={instance => {
                      setCropper(instance);
                    }}
                    guides
                    checkCrossOrigin={false}
                  />
                </div>
              </div>
            </div>
          </Row>
        </form>
      </FormProvider>
    </Modal>
  );
};
