import * as yup from 'yup';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { isValidPhoneNumber } from 'libphonenumber-js/max';

import { DefaultContactOptionsTypes } from 'components/form/Contacts/ContactsSelector';
import { ExtensionContactsTypes } from 'components/form/ExtensionContacts/types';
import { inputUnmaskNumber } from 'constants/masks';

import { isEmpty, stripTagsAndTrim } from './helpers';
import { getValueByStringKeyWithArr } from './objects';

const notTypeHash = {
  number: 'Введите число',
};

yup.setLocale({
  mixed: {
    default: 'Значение введено неверно',
    required: 'Это поле необходимо заполнить',
    oneOf: 'Необходимо ввести одно из следующих значений: ${values}',
    notOneOf: 'Возможно ввести любые значения, кроме: ${values}',
    notType: ({ type }) => notTypeHash[type] || `Введите значение типа ${type}`,
  },
  string: {
    length: 'Введите точно ${length} символов',
    min: 'Введите минимум ${min} символов',
    max: 'Введите не более ${max} символов',
    matches: '${path} must match the following: "${regex}"',
    email: 'Введите корректный адрес электронной почты',
    url: 'Введите корректный URL',
  },
  number: {
    min: 'Введите число большее или равное ${min}',
    max: 'Введите число меньше или равное ${max}',
    positive: 'Введите положительное число',
    negative: 'Введите отрицательное число',
    integer: 'Введите целое число',
  },
  array: {},
  date: {
    min: 'Введите дату позднее чем ${min}',
    max: 'Введите дату раньше чем ${max}',
  },
});

export const timestampSchema = yup.date().transform(function (value, original) {
  const date = new Date(original);
  if (this.isType(date)) {
    return date;
  }

  return value;
});

export const passwordSchema = yup
  .string()
  .test('isMatch', 'Пароли должны совпадать', function (value) {
    return value === this.parent.password;
  });

export const mobilePhoneSchema = yup
  .string()
  .test(
    'isMatch',
    'Введите корректный номер мобильного телефона +7(9__) ___-__-__',
    function (value) {
      if (!value) {
        return true;
      }
      const formattedPhone = inputUnmaskNumber(value);
      return (
        formattedPhone.startsWith('+79') && isValidPhoneNumber(value, 'RU')
      );
    }
  );

export const shortPhoneSchema = yup
  .string()
  .test(
    'isMatch',
    'Введите корректный номер телефона +7(___) ___-__-__',
    function (value) {
      if (!value) {
        return true;
      }
      return isValidPhoneNumber(value, 'RU');
    }
  );

export const addressSchema = yup.object().shape({
  region: yup.mixed().required(),
  area: yup.mixed(),
  city: yup.mixed().test({
    test: function (city) {
      const { settlement } = this.parent;
      return city || settlement;
    },
    message: 'Это поле необходимо заполнить',
  }),
  settlement: yup.mixed(),
  street: yup.mixed(),
  house: yup.mixed(),
  comment: yup.string().max(255),
});

export const addressContractSchema = yup.object().shape({
  region: yup.mixed().required(),
  area: yup.mixed(),
  city: yup.mixed(),
});

export const mapPositionSchema = yup.mixed().test({
  test: function (mapPosition) {
    if (!this.parent.city) {
      return true;
    } else {
      return mapPosition && mapPosition.length === 2;
    }
  },
  message: 'Необходимо указать точку на карте',
});

export const locationSchema = yup
  .object()
  .shape({
    mapPosition: mapPositionSchema,
  })
  .concat(addressSchema);

export const scheduleItemSchema = yup.object().shape({
  from: yup
    .number()
    .nullable()
    .test({
      test: function (from) {
        return this.parent.to === null || from !== null;
      },
      message: 'Должно быть указано время начала',
    }),
  to: yup
    .number()
    .nullable()
    .test({
      test: function (to) {
        return this.parent.from === null || to !== null;
      },
      message: 'Должно быть указано время окончания работы',
    }),
});

export const widgetAddressSchema = yup.object().shape({
  region: yup.mixed().required(),
  area: yup.mixed().test({
    test: function () {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { region, ...value } = this.parent;
      return isEmpty(value);
    },
    message: 'Это поле необходимо заполнить',
  }),
  city: yup.mixed(),
  settlement: yup.mixed(),
  street: yup.mixed(),
  house: yup.mixed(),
});

export const widgetLocationSchema = yup.object().shape({
  locale: yup.mixed(),
  address: widgetAddressSchema.required(),
  mapPosition: mapPositionSchema,
});

export const imageSchema = yup.object().shape({
  source: yup.string().required(),
  description: yup.string().trim(),
  author: yup.string().required(),
  file: yup.mixed(),
  averageColor: yup.string(),
  path: yup.string().trim(),
});

export const contentSchema = yup
  .array()
  .of(
    yup.object().shape({
      type: yup
        .string()
        .oneOf([
          'text',
          'image',
          'gallery',
          'video',
          'geoPosition',
          'widget',
          'collage',
          'quote',
          'separator',
        ])
        .required(),
      text: yup
        .string()
        .when('type', {
          is: 'text',
          then: yup.string(),
        })
        .test('text', 'Это поле необходимо заполнить', function () {
          // 'from' will contain the ancestor tree. You can access like from[1], from[2].
          // проверка на заполненость контента, сделана здесь, чтобы корректно очищались ошибки(react-hook-form) при вводе текста
          const { from, path } = this as any;
          const parentName = path.substring(0, path.lastIndexOf('['));
          //reverse array to get actual full form data, so path can be used to find local parent
          const fullData = from.reverse()[0];
          const value = getValueByStringKeyWithArr(fullData.value, parentName);
          if (value.length === 1 && value[0].type === 'text') {
            return stripTagsAndTrim(value[0].text);
          }
          return true;
        }),
      image: yup.mixed().when('type', {
        is: 'image',
        then: imageSchema.required(),
      }),
      gallery: yup.array().when('type', {
        is: 'gallery',
        then: yup.array().of(imageSchema).required(),
      }),
      video: yup.mixed().when('type', {
        is: 'video',
        then: yup.string().url().required(),
      }),
      geoPosition: yup.mixed().when('type', {
        is: 'geoPosition',
        then: yup
          .object()
          .shape({
            description: yup.string().trim().max(255).required(),
            image: imageSchema.required(),
            url: yup.string().url(),
            address: locationSchema,
            title: yup.string().max(256).required(),
          })
          .required(),
      }),
      widget: yup.object().when('type', {
        is: 'widget',
        then: yup
          .object()
          .shape({
            type: yup
              .string()
              .oneOf([
                'attractions',
                'events',
                'resorts',
                'hotels',
                'restaurants',
                'articles',
                'news',
                'routes',
              ])
              .required(),
            attractions: yup.mixed().when('type', {
              is: 'attractions',
              then: yup.mixed().required(),
            }),
            events: yup.mixed().when('type', {
              is: 'events',
              then: yup.mixed().required(),
            }),
            resorts: yup.mixed().when('type', {
              is: 'resorts',
              then: yup.mixed().required(),
            }),
            hotels: yup.mixed().when('type', {
              is: 'hotels',
              then: yup.mixed().required(),
            }),
            restaurants: yup.mixed().when('type', {
              is: 'restaurants',
              then: yup.mixed().required(),
            }),
            articles: yup.mixed().when('type', {
              is: 'articles',
              then: yup.mixed().required(),
            }),
            news: yup.mixed().when('type', {
              is: 'news',
              then: yup.mixed().required(),
            }),
            routes: yup.mixed().when('type', {
              is: 'routes',
              then: yup.mixed().required(),
            }),
            entityDescription: yup.string().trim().max(512),
          })
          .required(),
      }),
      collage: yup.object().when('type', {
        is: 'collage',
        then: yup
          .object()
          .shape({
            images: yup.array().of(imageSchema).required(),
          })
          .required(),
      }),
      quote: yup.mixed().when('type', {
        is: 'quote',
        then: yup
          .object()
          .shape({
            activity: yup
              .string()
              .trim()
              .max(60, 'Введите не более 60 символов')
              .required(),
            author: yup
              .string()
              .trim()
              .max(60, 'Введите не более 60 символов')
              .required(),
            quote: yup
              .string()
              .trim()
              .max(255, 'Введите не более 255 символов')
              .required(),
            image: yup.mixed().when({
              is: el => {
                if (el && typeof el === 'object') {
                  return Object.keys(el).length;
                }
                return el;
              },
              then: imageSchema.required(),
            }),
          })
          .required(),
      }),
    })
  )
  .required();

export const seoSchema = yup.object().shape({
  title: yup.string().max(256),
  description: yup.string().trim().max(256),
});

export const tagsSchema = yup.mixed().test({
  test: function (value) {
    const { category } = this.parent;
    return !category || value.find(item => item._id === category._id);
  },
  message: 'Теги не могут пересекаться с категорией',
});

export const validateDomain = yup.string().url().required();

export const contactsSchema = yup.array().of(
  yup.object().shape({
    type: yup
      .string()
      .oneOf([
        DefaultContactOptionsTypes.Phone,
        DefaultContactOptionsTypes.Email,
        DefaultContactOptionsTypes.Site,
        DefaultContactOptionsTypes.VK,
        DefaultContactOptionsTypes.OK,
        DefaultContactOptionsTypes.Telegram,
        DefaultContactOptionsTypes.YandexDzen,
      ])
      .required(),
    value: yup
      .string()
      .when('type', {
        is: DefaultContactOptionsTypes.Phone,
        then: shortPhoneSchema.required(),
      })
      .when('type', {
        is: DefaultContactOptionsTypes.Email,
        then: yup.string().required().email(),
      })
      .when('type', {
        is: DefaultContactOptionsTypes.Site,
        then: validateDomain,
      })
      .when('type', {
        is: DefaultContactOptionsTypes.VK,
        then: validateDomain,
      })
      .when('type', {
        is: DefaultContactOptionsTypes.OK,
        then: validateDomain,
      })
      .when('type', {
        is: DefaultContactOptionsTypes.Telegram,
        then: validateDomain,
      })
      .when('type', {
        is: DefaultContactOptionsTypes.YandexDzen,
        then: validateDomain,
      }),
  })
);

export const audioSchema = yup.object().shape({
  realName: yup.string(),
  path: yup.string(),
  baseUrl: yup.string(),
});

const routesPlaceSchema = yup.object().shape({
  placeType: yup.mixed().required(),
  place: yup.mixed().required(),
  duration: yup.string(),
  description: contentSchema.required(),
  audio: audioSchema.nullable(),
});

const routesManualSchema = yup.object().shape({
  routeName: yup.string().required(),
  shortDescription: yup.string().required(),
  description: contentSchema.required(),
  address: locationSchema,
  image: imageSchema,
  duration: yup.string(),
  audio: audioSchema.nullable(),
});

export const routePointsSchema = yup.array().of(
  yup.lazy(value => {
    if (value.type === 'place') {
      return routesPlaceSchema.required();
    }
    if (value.type === 'manual') {
      return routesManualSchema.required();
    }
    return yup.mixed().notRequired();
  }) as any
);

export const getCollapseTabsWithError = <T extends FieldValues>(
  errors: FieldErrors<T>,
  collapseFieldsErrors: Record<string, string>
) =>
  Object.keys(errors).reduce<string[]>((acc, item) => {
    if (collapseFieldsErrors[item]) {
      acc.push(collapseFieldsErrors[item]);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

export const applicationsMaterialsFileSchema = yup.object().shape({
  type: yup.string().required(),
  file: yup.object().shape({
    path: yup.string().required('Загрузите файл'),
    displayName: yup.string().required(),
  }),
});

export const applicationsMaterialsUrlSchema = yup.object().shape({
  type: yup.string().required(),
  url: yup.object().shape({
    url: yup.string().url().required(),
    displayName: yup.string().required(),
  }),
});

export const applicationsMaterialsSchema = yup.array().of(
  yup.lazy(material => {
    if (material.type === 'file') {
      return applicationsMaterialsFileSchema.required();
    } else {
      return applicationsMaterialsUrlSchema;
    }
  }) as any
);

export const additionalPhoneNumberSchema = yup.string().test({
  test: function (additionalValue) {
    if (additionalValue) {
      return additionalValue.length >= 3 && additionalValue.length <= 4;
    }

    return true;
  },
  message: 'Введите от 3-x до 4-x символов',
});

export const applicationsContactsSchema = yup.array().of(
  yup.object().shape({
    type: yup
      .string()
      .oneOf([ExtensionContactsTypes.email, ExtensionContactsTypes.phone])
      .required(),
    value: yup
      .string()
      .when('type', {
        is: ExtensionContactsTypes.email,
        then: yup.string().email().required(),
      })
      .when('type', {
        is: ExtensionContactsTypes.phone,
        then: shortPhoneSchema.required(),
      }),
    additional: yup.string().when('type', {
      is: ExtensionContactsTypes.phone,
      then: additionalPhoneNumberSchema,
    }),
  })
);

/** Проверка на то, что поле содержит только пробелы **/
export const notRequiredStringValidationSchema = yup
  .string()
  .notRequired()
  .test('notEmpty', 'Это поле необходимо заполнить', value => {
    return !(value && /^\s*$/.test(value));
  });

export const seoValidationSchema = yup.object().shape({
  metaTitle: notRequiredStringValidationSchema.max(100),
  metaDescription: notRequiredStringValidationSchema.max(150),
  ogTitle: notRequiredStringValidationSchema.max(100),
  ogDescription: notRequiredStringValidationSchema.max(150),
  metaKeywords: notRequiredStringValidationSchema.max(150),
});

export const preferencesValidationSchema = yup.array().of(
  yup.object().shape({
    icon: yup.mixed().required('Загрузите изображение'),
    title: yup
      .string()
      .trim()
      .min(1, 'Введите от 1 до 40 символов')
      .max(40, 'Введите от 1 до 40 символов')
      .required(),
    description: yup
      .string()
      .trim()
      .min(1, 'Введите от 1 до 160 символов')
      .max(160, 'Введите от 1 до 160 символов')
      .required(),
    alt: yup.string().trim().max(160, 'Введите не более 160 символов'),
  })
);

/**
 * Валидация url видео(vk, youtube, rutube, ok)
 */
export const videoValidationSchema = yup
  .string()
  .test(
    'validate-video',
    'Необходимо добавить видео с доступных хостингов.',
    value => {
      const regExpOk = /(http|https:\/\/)?ok\.ru\/(video|live)\/([0-9]+)/;
      const regExpVk1 = /(http|https:\/\/)?vk\.com\/video_ext.php\?/;
      const regExpVk2 =
        /(http|https:\/\/)?vk\.com\/\S*(video(\?|)(z=video)?|(videos))(-?[0-9]+_[0-9]+)/;
      const regExpYoutube =
        /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\/?\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/;
      const regExpRutube =
        /(http|https:\/\/)?rutube\.ru\/(video|live)\/([a-z0-9]+)/;

      return (
        regExpOk.test(value) ||
        regExpYoutube.test(value) ||
        regExpRutube.test(value) ||
        regExpVk1.test(value) ||
        regExpVk2.test(value)
      );
    }
  );

export default yup;
