import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Space, Typography } from 'antd';

import { ImagePreview } from 'components/form/ImagePreview/ImagePreview';
import { makeAddressString } from 'utils/entities';

type TProps = {
  widget: any;
};

export const WidgetPreview: React.FC<TProps> = ({ widget }) => {
  const entityUrl = `/${widget.type}/${widget.id}/edit`;

  const address = widget.address || widget.location?.address;
  return (
    <Card>
      <Row gutter={[20, 20]}>
        {widget.image && (
          <Col flex={'0 1 200px'}>
            <div
              style={{
                border: '1px solid #d9d9d9',
              }}
            >
              <ImagePreview
                className='content-widget_image'
                value={widget.image}
                alt={widget.name}
              />
            </div>
          </Col>
        )}
        <Col flex={'1 1 400px'}>
          <Space direction='vertical' style={{ wordBreak: 'break-word' }}>
            <Link to={entityUrl} target='_blank'>
              <Typography.Title level={4}>{widget.name}</Typography.Title>
            </Link>
            {address && (
              <div>{makeAddressString(address, { withComment: true })}</div>
            )}
            {widget.shortDescription && (
              <div style={{ wordBreak: 'break-word' }}>
                {widget.shortDescription}
              </div>
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};
