import React from 'react';
import { Card, Col, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { InputField } from 'components/form/base/InputField';
import { MainImage } from 'components/form/MainImage/MainImage';
import { TextAreaField } from 'components/form/base/TextAreaField';
import { PREVIEW_ASPECT_RATIO } from 'constants/image';

import styles from './Quote.module.less';

type TProps = {
  error: any;
  errorRef: any;
  onChange: (value: any) => void;
  quote: any;
  name: string;
};

export class Quote extends React.Component<TProps> {
  static displayName = 'FormContent:Quote';

  static defaultProps = {
    quote: {
      activity: '',
      author: '',
      image: null,
      quote: '',
    },
    onChange: () => undefined,
  };

  render(): React.ReactNode {
    return (
      <Content style={{ paddingTop: 0, paddingBottom: 0 }}>
        <div ref={this.props.errorRef} tabIndex={0}>
          <Card className={styles.card} title='Цитата' type='inner'>
            <Row>
              <Col span={24}>
                <div className={styles.authorInfo}>
                  <MainImage
                    className={styles.image}
                    name={`${this.props.name}.image`}
                    label='Фото автора'
                    aspectRatio={PREVIEW_ASPECT_RATIO}
                  />
                  <div>
                    <InputField
                      name={`${this.props.name}.author`}
                      label='Автор'
                      placeholder='Введите имя автора'
                      minLength={1}
                      maxLength={60}
                      required
                      // onChange={this.props.onChange}
                      value={this.props.quote.author}
                    />

                    <InputField
                      name={`${this.props.name}.activity`}
                      label='Деятельность'
                      placeholder='Введите деятельность автора'
                      minLength={1}
                      maxLength={60}
                      required
                      // onChange={this.props.onChange}
                      value={this.props.quote.activity}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <TextAreaField
                  label='Текст цитаты'
                  name={`${this.props.name}.quote`}
                  placeholder='Описание'
                  showCount
                  maxLength={255}
                  rows={10}
                  required
                  value={this.props.quote.quote}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </Content>
    );
  }
}
