import React from 'react';
import { Link } from 'react-router-dom';
import {
  AlertOutlined,
  BookOutlined,
  CalendarOutlined,
  CommentOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  GlobalOutlined,
  HddOutlined,
  HistoryOutlined,
  IdcardOutlined,
  LikeOutlined,
  MessageOutlined,
  NodeIndexOutlined,
  PictureOutlined,
  ProfileOutlined,
  ReconciliationOutlined,
  SaveOutlined,
  SettingOutlined,
  SolutionOutlined,
  StarOutlined,
  TeamOutlined,
  WalletOutlined,
} from '@ant-design/icons';

import { PageTitles } from 'constants/pageTitles';
import { RubCircleSvg } from 'components/icons/icons';
import { ApplicationsStatusesFormEnum } from 'constants/applicationsFormRespondsStatus';
import { Role } from 'constants/roles';
import { ROUTES_PATHS_BY_ROLES } from 'constants/routes';
import { attractionsRoutes } from 'pages/Attractions/routes';
import { resortsRoutes } from 'pages/Resorts/routes';
import { hotelsRoutes } from 'pages/Hotels/routes';
import { restaurantsRoutes } from 'pages/Restaurants/routes';
import { infrastructuresRoutes } from 'pages/Infrastructures/routes';
import { newsRoutes } from 'pages/News/routes';
import { articlesRoutes } from 'pages/Articles/routes';
import { touristRoutesRoutes } from 'pages/TouristRoutes/routes';
import { eventsRoutes } from 'pages/Events/routes';
import { regionsRoutes } from 'pages/Regions/routes';
import { reviewsRoutes } from 'pages/Reviews/routes';
import { materialsRoutes } from 'pages/Materials/routes';
import { faqRoutes } from 'pages/FAQ/routes';
import { investmentProjectsRoutes } from 'pages/InvestmentProjects/routes';
import { investmentPassportsRoutes } from 'pages/InvestmentPassports/routes';
import { investInfrastructuresRoutes } from 'pages/InvestmentInfrastructures/routes';
import { supportMeasuresRoutes } from 'pages/SupportMeasures/routes';
import { projectsRoutes } from 'pages/Projects/routes';
import { applicationsRoutes } from 'pages/Applications/routes';
import { reportsRoutes } from 'pages/Reports/routes';
import { applicationsFormRoutes } from 'pages/ApplicationsFromForms/routes';
import { organizationsRoutes } from 'pages/Organizations/routes';
import { touristRoutes } from 'pages/Tourists/routes';
import { businessRoutes } from 'pages/Business/routes';
import { adminRoutes } from 'pages/Admin/routes';
import { purchasesRoutes } from 'pages/Purchases/routes';
import { salesRoutes } from 'pages/Sales/routes';
import { rentalsRoutes } from 'pages/Rentals/routes';
import { signedAgreementsRoutes } from 'pages/SignedAgreements/routes';
import { purchasePlansRoutes } from 'pages/PurchasePlans/routes';
import { regulationsRoutes } from 'pages/Regulations/routes';
import { vacanciesRoutes } from 'pages/Vacancies/routes';
import { contractExchangeRoutes } from 'pages/ContractExchange/routes';
import { priorityIndustryRoutes } from 'pages/PriorityIndustry/routes';
import { openingInfoRoutes } from 'pages/OpeningInfo/routes';
import { catalogRoutes } from 'pages/Catalog/routes';
import { menuRoutes } from 'pages/Menu/routes';
import { mainBusinessRoutes } from 'pages/MainBusiness/routes';
import { mainTourismRoutes } from 'pages/MainTourism/routes';
import { attractionsSampleRoutes } from 'pages/AttractionsSample/routes';
import { resortsSampleRoutes } from 'pages/ResortsSample/routes';
import { restaurantsSampleRoutes } from 'pages/RestaurantsSample/routes';
import { eventsSampleRoutes } from 'pages/EventsSample/routes';
import { hotelsSampleRoutes } from 'pages/HotelsSample/routes';
import { routesSampleRoutes } from 'pages/RoutesSample/routes';
import { alertB2BRoutes, alertB2CRoutes } from 'pages/Alert/routes';
import { activitiesRoutes } from 'pages/Activities/routes';
import { currentResidentsRoutes } from 'pages/CurrentResidents/routes';
import { investmentSolutionsRoutes } from 'pages/InvestmentSolutions/routes';
import { aboutRegionRoutes } from 'pages/AboutRegion/routes';
import { mainB2CRoutes } from 'pages/MainB2C/routes';

export const SIDEBAR_LIST = [
  {
    key: 'places',
    label: 'Места',
    icon: <EnvironmentOutlined />,
    children: [
      {
        key: attractionsRoutes.path,
        label: (
          <Link to={attractionsRoutes.path}>{PageTitles.attractions}</Link>
        ),
      },
      {
        key: resortsRoutes.path,
        label: <Link to={resortsRoutes.path}>{PageTitles.resorts}</Link>,
      },
      {
        key: hotelsRoutes.path,
        label: <Link to={hotelsRoutes.path}>{PageTitles.hotels}</Link>,
      },
      {
        key: restaurantsRoutes.path,
        label: (
          <Link to={restaurantsRoutes.path}>{PageTitles.restaurants}</Link>
        ),
      },
      {
        key: infrastructuresRoutes.path,
        label: (
          <Link to={infrastructuresRoutes.path}>
            {PageTitles.infrastructures}
          </Link>
        ),
      },
    ],
  },
  {
    key: 'sample',
    label: 'Выборка',
    icon: <EnvironmentOutlined />,
    children: [
      {
        key: attractionsSampleRoutes.path,
        label: (
          <Link to={attractionsSampleRoutes.path}>
            {PageTitles.attractions}
          </Link>
        ),
      },
      {
        key: resortsSampleRoutes.path,
        label: <Link to={resortsSampleRoutes.path}>{PageTitles.resorts}</Link>,
      },
      {
        key: hotelsSampleRoutes.path,
        label: <Link to={hotelsSampleRoutes.path}>{PageTitles.hotels}</Link>,
      },
      {
        key: restaurantsSampleRoutes.path,
        label: (
          <Link to={restaurantsSampleRoutes.path}>
            {PageTitles.restaurants}
          </Link>
        ),
      },
      {
        key: routesSampleRoutes.path,
        label: (
          <Link to={routesSampleRoutes.path}>{PageTitles.touristRoutes}</Link>
        ),
      },
      {
        key: eventsSampleRoutes.path,
        label: <Link to={eventsSampleRoutes.path}>{PageTitles.events}</Link>,
      },
    ],
  },
  {
    key: 'publications',
    label: 'Публикации',
    icon: <PictureOutlined />,
    children: [
      {
        key: newsRoutes.path,
        label: <Link to={newsRoutes.path}>{PageTitles.news}</Link>,
      },
      {
        key: articlesRoutes.path,
        label: <Link to={articlesRoutes.path}>{PageTitles.articles}</Link>,
      },
    ],
  },
  {
    key: touristRoutesRoutes.path,
    label: (
      <Link to={touristRoutesRoutes.path}>{PageTitles.touristRoutes}</Link>
    ),
    icon: <NodeIndexOutlined />,
  },
  {
    key: eventsRoutes.path,
    label: <Link to={eventsRoutes.path}>{PageTitles.events}</Link>,
    icon: <CalendarOutlined />,
  },
  {
    key: regionsRoutes.path,
    label: <Link to={regionsRoutes.path}>{PageTitles.regions}</Link>,
    icon: <GlobalOutlined />,
  },
  {
    key: reviewsRoutes.path,
    label: <Link to={reviewsRoutes.path}>{PageTitles.reviews}</Link>,
    icon: <MessageOutlined />,
  },
  {
    key: materialsRoutes.path,
    label: <Link to={materialsRoutes.path}>{PageTitles.materials}</Link>,
    icon: <SaveOutlined />,
  },
  {
    key: faqRoutes.path,
    label: <Link to={faqRoutes.path}>{PageTitles.faq}</Link>,
    icon: <CommentOutlined />,
  },
  {
    key: 'investments',
    label: 'Инвестиции',
    icon: (
      <span
        className='anticon anticon-global ant-menu-item-icon'
        role='img'
        aria-label='rub-circle'
      >
        <RubCircleSvg />
      </span>
    ),
    children: [
      {
        key: investmentProjectsRoutes.path,
        label: <Link to={investmentProjectsRoutes.path}>Инвестпроекты</Link>,
      },
      {
        key: investmentPassportsRoutes.path,
        label: <Link to={investmentPassportsRoutes.path}>Паспорта</Link>,
      },
      {
        key: investInfrastructuresRoutes.path,
        label: (
          <Link to={investInfrastructuresRoutes.path}>Инфраструктура</Link>
        ),
      },
    ],
  },
  {
    key: currentResidentsRoutes.path,
    label: (
      <Link to={currentResidentsRoutes.path}>
        {PageTitles.currentResidents}
      </Link>
    ),
    icon: <StarOutlined />,
  },
  {
    key: investmentSolutionsRoutes.path,
    label: (
      <Link to={investmentSolutionsRoutes.path}>
        {PageTitles.investmentSolutions}
      </Link>
    ),
    icon: <SolutionOutlined />,
  },
  {
    key: supportMeasuresRoutes.path,
    label: (
      <Link to={supportMeasuresRoutes.path}>{PageTitles.supportMeasures}</Link>
    ),
    icon: <LikeOutlined />,
  },
  {
    key: projectsRoutes.path,
    label: <Link to={projectsRoutes.path}>{PageTitles.projects}</Link>,
    icon: <ProfileOutlined />,
  },
  {
    key: applicationsRoutes.path,
    label: <Link to={applicationsRoutes.path}>{PageTitles.applications}</Link>,
    icon: <HddOutlined />,
  },
  {
    key: reportsRoutes.path,
    label: <Link to={reportsRoutes.path}>{PageTitles.reports}</Link>,
    icon: <ReconciliationOutlined />,
  },
  {
    key: organizationsRoutes.path,
    label: (
      <Link to={organizationsRoutes.path}>{PageTitles.organizations}</Link>
    ),
    icon: <IdcardOutlined />,
  },
  {
    key: 'users',
    label: 'Пользователи',
    icon: <TeamOutlined />,
    children: [
      {
        key: touristRoutes.path,
        label: <Link to={touristRoutes.path}>{PageTitles.tourist}</Link>,
      },
      {
        key: businessRoutes.path,
        label: <Link to={businessRoutes.path}>{PageTitles.business}</Link>,
      },
      {
        key: adminRoutes.path,
        label: <Link to={adminRoutes.path}>{PageTitles.admin}</Link>,
      },
    ],
  },
  {
    key: applicationsFormRoutes.path,
    label: (
      <Link
        to={`${applicationsFormRoutes.path}?type=${ApplicationsStatusesFormEnum.investInfrastructureResponds}`}
      >
        {PageTitles.applicationsForm}
      </Link>
    ),
    icon: <FileTextOutlined />,
  },
  {
    key: 'docs',
    label: 'АО Кавказ.РФ',
    icon: <HddOutlined />,
    children: [
      {
        key: purchasesRoutes.path,
        label: <Link to={purchasesRoutes.path}>{PageTitles.purchases}</Link>,
      },
      {
        key: salesRoutes.path,
        label: <Link to={salesRoutes.path}>{PageTitles.sales}</Link>,
      },
      {
        key: rentalsRoutes.path,
        label: <Link to={rentalsRoutes.path}>{PageTitles.rentals}</Link>,
      },
      {
        key: signedAgreementsRoutes.path,
        label: (
          <Link
            to={signedAgreementsRoutes.path}
            style={{ whiteSpace: 'normal' }}
          >
            {PageTitles.signedAgreements}
          </Link>
        ),
      },
      {
        key: purchasePlansRoutes.path,
        label: (
          <Link to={purchasePlansRoutes.path}>{PageTitles.purchasePlans}</Link>
        ),
      },
      {
        key: regulationsRoutes.path,
        label: (
          <Link to={regulationsRoutes.path}>{PageTitles.regulations}</Link>
        ),
      },
      {
        key: vacanciesRoutes.path,
        label: <Link to={vacanciesRoutes.path}>{PageTitles.vacancies}</Link>,
      },
    ],
  },
  {
    key: contractExchangeRoutes.path,
    label: (
      <Link to={contractExchangeRoutes.path}>
        {PageTitles.contractExchange}
      </Link>
    ),
    icon: <WalletOutlined />,
  },
  {
    key: 'settings',
    label: 'Настройка страниц',
    icon: <SettingOutlined />,
    children: [
      {
        key: mainB2CRoutes.path,
        label: <Link to={mainB2CRoutes.path}>{PageTitles.mainB2C}</Link>,
      },
      {
        key: priorityIndustryRoutes.path,
        label: (
          <Link to={priorityIndustryRoutes.path}>
            {PageTitles.priorityIndustry}
          </Link>
        ),
      },
      {
        key: openingInfoRoutes.path,
        label: (
          <Link to={openingInfoRoutes.path}>{PageTitles.openingInfo}</Link>
        ),
      },
      {
        key: mainBusinessRoutes.path,
        label: (
          <Link to={mainBusinessRoutes.path}>{PageTitles.mainBusiness}</Link>
        ),
      },
      {
        key: aboutRegionRoutes.path,
        label: (
          <Link to={aboutRegionRoutes.path}>{PageTitles.aboutRegion}</Link>
        ),
      },
    ],
  },
  {
    key: 'directories',
    label: 'Справочники',
    icon: <BookOutlined />,
    children: [
      {
        key: catalogRoutes.path,
        label: <Link to={catalogRoutes.path}>{PageTitles.catalog}</Link>,
      },
      {
        key: menuRoutes.path,
        label: <Link to={menuRoutes.path}>{PageTitles.menu}</Link>,
      },
    ],
  },
  {
    key: 'alerts',
    icon: <AlertOutlined />,
    label: PageTitles.alert,
    children: [
      {
        key: alertB2CRoutes.path,
        label: <Link to={alertB2CRoutes.path}>{PageTitles.alertB2C}</Link>,
      },
      {
        key: alertB2BRoutes.path,
        label: <Link to={alertB2BRoutes.path}>{PageTitles.alertB2B}</Link>,
      },
    ],
  },
  {
    key: activitiesRoutes.path,
    label: <Link to={activitiesRoutes.path}>{PageTitles.activities}</Link>,
    icon: <HistoryOutlined />,
  },
];

export const getFilteredSidebarListByRole = (role: Role) => {
  return SIDEBAR_LIST.map(item => {
    if (item.children) {
      return {
        ...item,
        children: item.children.filter(({ key }) =>
          ROUTES_PATHS_BY_ROLES[role].includes(key)
        ),
      };
    }
    return item;
  }).filter(({ children, key }) => {
    if (children) {
      return !!children.length;
    }
    return ROUTES_PATHS_BY_ROLES[role].includes(key);
  });
};
