import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Button, Input } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { apiClient } from '../../../../../../utils/http';
import { urls } from '../../../../../../store/api';
import { debounce } from '../../../../../../utils/helpers';
import { MainImage } from '../../../../../../components/form/MainImage/MainImage';
import { InputField } from '../../../../../../components/form/base/InputField';
import { CheckboxField } from '../../../../../../components/form/base/CheckboxField';
import {
  MAIN_B2C_ITEM_SETTINGS,
  MAIN_B2C_PREVIEW_ITEM_TYPE,
  MAIN_B2C_TABLE_LABELS,
  MAIN_B2C_TABLE_NAMES,
} from '../../../../const';
import styles from './CreatePreview.module.less';
import yup, { imageSchema } from '../../../../../../utils/yup';
import type { IRowPreviewItem } from '../../../../types/types';

export const VALIDATION_SCHEMA = yup.object().shape({
  image: imageSchema.shape({
    author: yup.string(),
    source: yup.string(),
  }),
  title: yup.string().required(),
  style: yup.boolean().required(),
  hideShortDescription: yup.boolean(),
  link: yup.string().required(),
});

interface ICreatePreviewProps {
  selectedType: keyof typeof MAIN_B2C_ITEM_SETTINGS;
  handleSubmit: (values: IRowPreviewItem) => void;
  setModifyingItem: (values: IRowPreviewItem) => void;
  modifyingItem: IRowPreviewItem;
}

export const CreatePreview: FC<ICreatePreviewProps> = ({
  selectedType,
  handleSubmit,
  modifyingItem,
  setModifyingItem,
}) => {
  const [searchLine, setSearchLine] = useState<string>('');
  const [catalogsData, setCatalogsData] = useState([]);
  const [cropIsOpen, setCropIsOpen] = useState(false);
  const [catalogsDataIsLoading, setCatalogsDataIsLoading] =
    useState<boolean>(false);
  const isMountedRef = useRef(false);

  const methods = useForm<IRowPreviewItem>({
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const { reset, watch } = methods;

  const getPreviewData = debounce(async () => {
    setCatalogsDataIsLoading(true);
    const searchParams = new URLSearchParams();
    searchParams.append('searchLine', searchLine);

    /* Бэкенд пустую строку не кушает :( */
    const path =
      searchLine === ''
        ? urls.api.previewB2C.materials.get
        : `${urls.api.previewB2C.materials.get}/?${searchParams.toString()}`;

    try {
      const { data } = await apiClient.get(path);

      setCatalogsData(data);
    } catch (e) {
      console.error(e);
    } finally {
      setCatalogsDataIsLoading(false);
    }
  }, 300);

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    getPreviewData(searchLine);
  };

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchLine(event.target.value);
  };

  const onSubmit = async values => {
    const reqObj = { ...values };
    let imageData;

    if ('file' in values.image) {
      const formData = new FormData();
      formData.append('image', values.image.file);
      try {
        const { data } = await apiClient.post(
          urls.api.upload.image.post,
          formData
        );
        imageData = {
          crop: values.image.crop,
          path: data.name,
          author: ' ',
          source: ' ',
          baseUrl: data.baseUrl,
        };
      } catch (error) {
        console.error(error);
      }
    } else {
      imageData = { ...values.image };
    }

    const reqBody = {
      ...reqObj,
      image: imageData,
      type: selectedType,
      status: 'published',
    };

    handleSubmit({
      ...(MAIN_B2C_ITEM_SETTINGS?.[selectedType] || {}),
      ...reqBody,
    });
  };

  const formValues = watch();
  const valueStyle = watch('style');
  const valueImage = watch('image');

  const handleSelectCatalog = async catalog => {
    reset({
      ...catalog,
      tags: [MAIN_B2C_TABLE_LABELS[catalog.tableName]],
      title: catalog.name,
      style: false,
      hideShortDescription: false,
      shortDescription: catalog.shortdescription,
      link: `${MAIN_B2C_TABLE_NAMES[catalog.tableName] ?? catalog.tableName}/${
        catalog.id
      }`,
    });
  };

  const onSubmitHandler = (values: IRowPreviewItem) => onSubmit(values);

  const darkThemeIsActive = useMemo(
    () => ['one_thirds', 'half'].includes(selectedType),
    [selectedType]
  );

  const imageAspectRatio = useMemo(() => {
    switch (selectedType) {
      case MAIN_B2C_PREVIEW_ITEM_TYPE.WIDE:
        return 1184 / 484;
      case MAIN_B2C_PREVIEW_ITEM_TYPE.TWO_THIRDS:
        return 776 / 480;
      case MAIN_B2C_PREVIEW_ITEM_TYPE.HALF:
        return valueStyle === true ? 572 / 426 : 572 / 308;
      case MAIN_B2C_PREVIEW_ITEM_TYPE.ONE_THIRDS:
        return valueStyle === true ? 369 / 480 : 368 / 184;
    }
    return undefined;
  }, [selectedType, valueStyle]);

  useEffect(() => {
    if (valueImage) {
      if (isMountedRef.current || modifyingItem === null) setCropIsOpen(true);
      else isMountedRef.current = true;
    }
  }, [valueStyle]);

  useEffect(() => {
    if (selectedType) {
      reset({ ...formValues, style: false });
    }
  }, [selectedType]);

  useEffect(() => {
    if (modifyingItem !== null) reset({ ...modifyingItem });
  }, [modifyingItem]);

  useEffect(() => {
    (async () => {
      await getPreviewData();
    })();

    return () => {
      setModifyingItem(null);
    };
  }, []);

  return (
    <div className={styles.createPreviewContainer}>
      <div className={styles.searchContainer}>
        <form onSubmit={handleSearch}>
          <Input
            onChange={handleSearchInput}
            placeholder='Поиск по названию'
            maxLength={255}
            addonAfter={
              <Button
                htmlType='submit'
                style={{ border: 'none', padding: '0', height: 'auto' }}
                disabled={catalogsDataIsLoading}
              >
                <SearchOutlined />
              </Button>
            }
          />
        </form>

        <div className={styles.catalogData}>
          {catalogsDataIsLoading ? (
            <LoadingOutlined />
          ) : (
            catalogsData?.map(el => (
              <div className={styles.catalogDataItem} key={el.createdAt}>
                <h3 className={styles.catalogDataItemName}>{el.name}</h3>
                <Button
                  className={styles.catalogDataItemButton}
                  onClick={() => handleSelectCatalog(el)}
                >
                  Выбрать
                </Button>
              </div>
            ))
          )}
        </div>
      </div>
      <div className={styles.createFormContainer}>
        <FormProvider {...methods}>
          <form
            className={classNames(
              'ant-form',
              'ant-form-vertical',
              styles.createPreviewForm
            )}
            onSubmit={methods.handleSubmit(onSubmitHandler)}
          >
            <MainImage
              name='image'
              label='Изображение'
              aspectRatio={imageAspectRatio}
              modalIsOpen={cropIsOpen}
              setModalIsOpen={setCropIsOpen}
              required
              isNoInputs
            />

            <InputField
              name='title'
              label='Заголовок'
              required
              placeholder='Введите заголовок'
            />
            <div className={styles.checkboxes}>
              <CheckboxField name='style' disabled={!darkThemeIsActive}>
                Темная тема
              </CheckboxField>
              <CheckboxField name='hideShortDescription'>
                Не показывать лид
              </CheckboxField>
            </div>
            <InputField
              name='shortDescription'
              label='Лид (краткое описание)'
              placeholder='Введите краткое описание'
            />
            <InputField
              name='link'
              label='Ссылка'
              placeholder='Введите краткое описание'
              required
            />

            <Button
              type='primary'
              htmlType='submit'
              style={{ marginTop: 'auto', alignSelf: 'end' }}
              loading={false}
            >
              Сохранить
            </Button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
